<template>
<a-menu-item
  v-if="taskInProgress"
  class="page-menu--item-height"
>
  <a-icon
    spin
    type="sync"
  />:
  {{ taskInProgress }}
</a-menu-item>
</template>


<script>
import {
  Menu,
  Icon,
} from 'ant-design-vue';


export default {
  name: 'InProgressMenuItem',
  components: {
    'a-menu-item': Menu.Item,
    'a-icon': Icon,
  },
  data() {
    return { taskInProgress: 0 };
  },
  created() {
    this.getProgressTimeoutId = 0;
    this.getProgressAbortController = this.$http.eAbortController;

    this.getProgress = () => {
      this.getProgressAbortController.abort();
      clearTimeout(this.getProgressTimeoutId);

      const getProgressAbortController = new AbortController();
      this.getProgressAbortController = getProgressAbortController;
      this.$http.get(
        'setups/bootloader/',
        { signal: getProgressAbortController.signal },
      )
        .then((response) => {
          if (getProgressAbortController !== this.getProgressAbortController) {
            clearTimeout(this.getProgressTimeoutId);
            return;
          }
          this.taskInProgress = response.data.task_in_progress;
          this.getProgressTimeoutId = setTimeout(this.getProgress, 8000);
        })
        .catch((error) => {
          if (this.$http.isCancel(error)) {
            clearTimeout(this.getProgressTimeoutId);
            return;
          }
          this.$message.error(
            this.$http.parseError(
              'Не удалось получить данные об обработки файлов',
              error,
            ),
            5,
          );
          this.getProgressTimeoutId = setTimeout(this.getProgress, 8000);
        });
    };

    this.getProgress();
  },
  beforeDestroy() {
    this.getProgressAbortController.abort();
    clearTimeout(this.getProgressTimeoutId);
  },
};
</script>
